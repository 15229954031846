.recurring__schedule__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .py-text {
        margin: 0 8px 0 8px;
        position: relative;
        background: #FFB191;
        color: $black;
        padding: 4px;
        height: 24px;
        line-height: 16px;
        border-radius: 4px;
        font-size: 14px;

        &:before,
        &:after {
            content: '';
            width: 10px;
            height: 2px;
            background: #FFB191;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &::before {
            left: -8px;
        }

        &::after {
            right: -8px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.recurring-payment-option {
    margin-top: 24px;
    .recurring-options {
        border: 1px solid #ededed;
        border-radius: 6px;

        .recurring-option {
            padding: 16px 16px 20px 16px;
            border-bottom: 1px solid #ededed;
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-child {
                border-bottom: none;
            }

            label {
                margin-bottom: 0;
                margin-right: 16px;
                font-weight: 700;
            }

            .form-control,
            .custom-select__control {
                min-height: 50px;
                border-radius: 6px;
                display: flex;
                max-width: 100%;
                min-width: 100px;
                text-align: center;
            }
            
            select.form-control {
                padding-right: 30px;
            }

            .ant-picker {
                width: 220px !important;

                input {
                    text-align: center;
                }
            }

                     
            .ending-number,  
            .ending-date {
                margin-left: 16px;
            }
        }
    }
}

@media (max-width: 768px) {    
    .recurring__schedule__container {
        flex-direction: column;
        width: 220px;
        max-width: 100%;

        .py-text {
            margin: 10px 0;

            &:before,
            &:after {                
                width: 2px;
                height: 10px;
                transform: translateX(-50%);
                left: 50%;
                top: -10px;
            }
            
            &:after {
                top: 24px;
            }   
        }
    }
    
    .recurring-payment-option {
        .recurring-options {
            .recurring-option {
                flex-direction: column;
                label {
                    margin-right: 0;
                    margin-bottom: 12px;
                }             
                .ending-number,  
                .ending-date {
                    margin-top: 16px;
                    margin-left: 0;
                }
                
                .form-control,
                .custom-select__control {
                    width: 220px !important;
                    max-width: 100% !important;

                    .custom-select__single-value {
                        padding-left: 15px;
                    }
                }
            }    
        }
    }
}